import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import { BsExclamationTriangle } from "react-icons/bs";

export const alertText = "Changes affect ACMG projects.";

const renderPopover = (text: string) => (
  <Popover id="alert-popover" className="alert-popover">
    {text}
  </Popover>
);

interface AlertProps {
  placement: Placement;
  text: string;
}

const Alert = (props: AlertProps) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement={props.placement}
      overlay={renderPopover(props.text)}>
      <div>
        <BsExclamationTriangle
          data-testid="alert-icon"
          className="alert-icon"
        />
      </div>
    </OverlayTrigger>
  );
};

Alert.defaultProps = {
  placement: "right",
  text: alertText,
};

export default Alert;
