import bind from "bind-decorator";
import LRU from "lru-cache";

type Loader<A extends any[], T> = {
  (...args: A): Promise<T>;
};

class AsyncCache<A extends any[], T> {
  size: number;
  // need to use strings as LRU keys since it only does string or strict
  // object equality
  cache: LRU<string, T>;
  loading: Map<string, Promise<T>>;

  loader: Loader<A, T>;

  constructor(size: number, loader: Loader<A, T>) {
    this.cache = new LRU({ max: size });
    this.loading = new Map();
    this.loader = loader;
  }

  @bind
  get(...key: A): T | undefined {
    return this.cache.get(key.toString());
  }

  @bind
  fetch(...key: A): Promise<T> {
    const keyStr = key.toString();
    const existing = this.cache.get(keyStr);
    if (existing !== undefined) {
      // in the cache
      // console.debug("cache hit in", this, "for", keyStr, existing);
      return Promise.resolve(existing);
    }
    const inFlight = this.loading.get(keyStr);
    if (inFlight !== undefined) {
      // already being loaded
      // console.debug("joining request in", this, "for", keyStr);
      return inFlight;
    }

    // load it
    // console.debug("cache miss in", this, "for", keyStr);
    const load = this.loader(...key)
      .finally(() => {
        this.loading.delete(keyStr);
      })
      .then((val) => {
        this.cache.set(keyStr, val);
        // console.debug("cache fetch finished in", this, "for", keyStr);
        return val;
      });
    this.loading.set(keyStr, load);
    return load;
  }

  clear() {
    this.cache.reset();
    this.loading.clear();
  }
}

export default AsyncCache;
